const lastNameInputEl = document.getElementById('last-name');
const idInputEl = document.getElementById('id');
const alertEl = document.querySelector('.alert');

// Declare a function to get query string
const getQueryString = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

// Declare a function to show error
const showError = (errormsg) => {
  document.querySelector('.alert').innerHTML = `<p>${errormsg}</p>`;
};

// Add a function to format the id
const formatId = (id) => {
  // add a hypen after the first two characters while allowing the user to delete the hyphen and the character before it
  if (id.length === 3 && id.charAt(2) !== '-') {
    id = id.slice(0, 2) + '-' + id.slice(2);
  }
  // only allow the input to be 13 characters long
  if (id.length > 12) {
    id = id.slice(0, 12);
  }
  return id;
};

const validateId = (id) => {
  if (id.length < 1) {
    if (idInputEl.classList.contains('invalid', 'is-invalid')) {
      idInputEl.classList.remove('invalid', 'is-invalid');
      document.querySelector('.invalid-msg').classList.add('d-none');
    }
  } else if (!/^\d{2}-\d{9}$/.test(id)) {
    idInputEl.classList.add('invalid', 'is-invalid');
    document.querySelector('.invalid-msg').classList.remove('d-none');
  } else {
    idInputEl.classList.remove('invalid', 'is-invalid');
    document.querySelector('.invalid-msg').classList.add('d-none');
  }
};

// Add a function to handle id input
const handleIdInput = (e) => {
  e.target.value = formatId(e.target.value);
  validateId(e.target.value);
};

//add a event listener to the id input element
idInputEl.addEventListener('input', handleIdInput, false);

// Add a function to handle last name input
const handleNameInput = (e) => {
  e.target.value = capitalizeFirstLetter(e.target.value);
};

const spaceRegex = /\s/g;
const plusRegex = /\+/g;
const specialChar = /%2B/g;

// Add a function to capitalize first letter of each word after a space and after a '+' sign
const capitalizeFirstLetter = (string) => {
  // return string.replace(/\b\w/g, (char) => char.toUpperCase());
  if (spaceRegex.test(string)) {
    return string
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  } else if (plusRegex.test(string)) {
    return string
      .split('+')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('+');
  } else {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};
// Capitalize first letter of last name input on keyup
lastNameInputEl.addEventListener('input', handleNameInput, false);

const error1 = 'We apologize, but something went wrong. Please try again.';
const error2 =
  "The system is not responding. Please try again later or email a copy of the front and back of the card to be verified to: <a href='mailto:usfotiec-cards@health.usf.edu'>usfotiec-cards@health.usf.edu</a>. USF OTIEC will provide a response to the verification request within two (2) business days.";
const error3 =
  "We have been unable to verify this student's information via the online system. For manual verification, please email a copy of the front and back of the card to be verified to: <a href='mailto:usfotiec-cards@health.usf.edu'>usfotiec-cards@health.usf.edu</a>. USF OTIEC will provide a response to the verification request within two (2) business days.";
const error4 = 'Additional information is required. Please fill out the missing information in the form below.';

const runMember = () => {
  // Get query string values
  const idQueryString = getQueryString('id');
  let lastNameQueryString = getQueryString('lastname');
  const spacePlusOrCharRegex = /\s|\+|%20/g;
  const specialChar = /%2B/g;

  // Check if idQueryString is not null
  if (idQueryString !== null && idQueryString !== '' && lastNameQueryString !== null && lastNameQueryString !== '') {
    // Set the idInputEl value to idQueryString
    idInputEl.value = idQueryString;
    // Set the lastNameInputEl value to lastNameQueryString
    if (spacePlusOrCharRegex.test(lastNameQueryString)) {
      document.querySelector('.section').innerHTML = '<div class="text-center"><img src="/images/loader.gif" /></div>';
      document.querySelector('.member-form').classList.add('d-none');
      alertEl.classList.add('d-none');
      lastNameInputEl.value = lastNameQueryString.replace(/\s|\+|%20/g, ' ');
      lastNameQueryString = lastNameQueryString.replace(/\s|%20/g, '+');
      verifyCredentials(idQueryString, lastNameQueryString);
    } else {
      if (specialChar.test(lastNameQueryString)) {
        lastNameInputEl.value = lastNameQueryString.replace(/%2B/g, ' ');
      } else {
        lastNameInputEl.value = lastNameQueryString;
      }
      document.querySelector('.section').innerHTML = '<div class="text-center"><img src="/images/loader.gif" /></div>';
      document.querySelector('.member-form').classList.add('d-none');
      alertEl.classList.add('d-none');
      // Call the verifyCredentials function
      verifyCredentials(idQueryString, lastNameQueryString);
    }
  }

  document.querySelector('form.member button[type=submit]').addEventListener('click', function (e) {
    e.preventDefault();
    const theForm = this.closest('form.member');
    let flag = 0;
    const idInputEl = theForm.querySelector('input[name=id]');
    const idValue = idInputEl.value;
    if (idValue === null || idValue === '' || idValue === 'Credential ID') {
      idInputEl.classList.add('invalid', 'is-invalid');
      flag = 1;
    } else {
      if (idInputEl.classList.contains('invalid', 'is-invalid')) {
        idInputEl.classList.remove('invalid', 'is-invalid');
      }
    }
    let lastNameVal = lastNameInputEl.value;
    if (lastNameVal === null || lastNameVal === '' || lastNameVal === 'Last Name') {
      lastNameInputEl.classList.add('invalid', 'is-invalid');
      flag = 1;
    } else {
      if (lastNameInputEl.classList.contains('invalid', 'is-invalid')) {
        lastNameInputEl.classList.remove('invalid', 'is-invalid');
      }
    }
    if (flag == 1) {
      alert('The highlighted field/s are required.');
    } else {
      document.querySelector('.member-form').classList.add('d-none');
      document.querySelector('.section').innerHTML = '<div class="text-center"><img src="/images/loader.gif" /></div>';
      alertEl.classList.add('d-none');
      if (spacePlusOrCharRegex.test(lastNameVal)) {
        lastNameInputEl.value = lastNameVal.replace(/\s|\+|%20/g, ' ');
        lastNameVal = lastNameVal.replace(/\s|\+|%20/g, '%2B');
      } else if (specialChar.test(lastNameVal)) {
        lastNameInputEl.value = lastNameVal.replace(/%2B/g, ' ');
      }
      window.location.href = '?id=' + idValue + '&lastname=' + lastNameVal;
      verifyCredentials(idValue, lastNameVal);
    }
  });
};

const verifyCredentials = (id, lastName) => {
  const data = {
    affiliateId: 1108,
    credentialId: id,
    lastName: lastName,
  };
  fetch('https://amersc.azure-api.net/ucr/v2/verify', {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': '63a869818f73498cbfa3f6b98f77994d',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((response) => {
      // console.log(response);
      if (response.statusCode === 404) {
        alertEl.classList.remove('d-none');
        alertEl.innerHTML = `<p>${error3}</p>`;
        document.querySelector('.status').classList.add('d-none');
        document.querySelector('.section').innerHTML = '';
        lastNameInputEl.value = lastName.replace(/%2B|\+/g, ' ');
        if (document.querySelector('.member-form').classList.contains('d-none')) {
          document.querySelector('.member-form').classList.remove('d-none');
        }
      }
      if (response.statusCode >= 500 || response.statusCode === 400 || response.statusCode === 401) {
        alertEl.classList.remove('d-none');
        alertEl.innerHTML = `<p>${error2}</p>`;
        document.querySelector('.status').classList.add('d-none');
        document.querySelector('.section').innerHTML = '';
        lastNameInputEl.value = lastName.replace(/%2B|\+/g, ' ');
        if (document.querySelector('.member-form').classList.contains('d-none')) {
          document.querySelector('.member-form').classList.remove('d-none');
        }
      }
      const cardStatus = response.cardStatus;
      if (cardStatus != null) {
        document.querySelector('.status .invalid').innerHTML =
          '<i class="icon-close"></i> <span class="text">' + response.cardStatus + '</span>';
        document.querySelector('.status .invalid').classList.remove('d-none');
        document.querySelector('.status .invalid').classList.add('d-block');
        document.querySelector('.status .invalid-desc').classList.remove('d-none');
        console.log('Invalid');
      } else {
        document.querySelector('.status .valid').classList.remove('d-none');
      }
      const responselastName = response.lastName;
      let validCleanedLastName;
      const plusRegex = /\+/g;
      if (plusRegex.test(lastName)) {
        validCleanedLastName = responselastName.replace(/\+/g, ' ');
      } else {
        validCleanedLastName = responselastName;
      }
      document.querySelector('.section').innerHTML =
        '<p class="d-block d-md-inline-block"><strong>ID:</strong> ' +
        response.credentialId +
        '</p><br class="d-none d-md-block" />\
            <p class="d-block d-md-inline-block"><strong>Course Name:</strong> ' +
        response.courseName +
        '</p><br class="d-none d-md-block" />\
            <p class="d-block d-md-inline-block"><strong>First Name:</strong> ' +
        response.firstName +
        '</p>\
            <p class="d-block d-md-inline-block"><strong>Last Name:</strong> ' +
        validCleanedLastName +
        '</p><br class="d-none d-md-inline-block" />\
            <p class="d-block d-md-inline-block"><strong>Graduation Date:</strong> ' +
        response.graduationDate.substring(0, 10) +
        '</p>';
    })
    .catch((error) => {
      console.log(error);
    });
};

alertEl.classList.remove('d-none');
alertEl.innerHTML = `<p class="text-center">${error4}</p>`;
runMember();
